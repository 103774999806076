import { AnchorHTMLAttributes, ComponentProps, FC } from 'react'

import { cva } from 'class-variance-authority'
import { twJoin, twMerge } from 'tailwind-merge'

import { IconSmallLogo } from '~/components/ui/icons/small-logo'

type LinkToMapVariant = 'default' | 'mini' | 'over'

const variantSetting: Record<
  LinkToMapVariant,
  { text: string; iconProps: ComponentProps<typeof IconSmallLogo> }
> = {
  default: {
    text: '地図を表示',
    iconProps: {},
  },
  mini: {
    text: '地図を表示',
    iconProps: { width: 21, height: 26 },
  },
  over: {
    text: '地図で表示する',
    iconProps: { fill: 'white' },
  },
}

const boxVariants = cva(null, {
  variants: {
    variant: {
      default:
        'bg-[url("/images/map.png")] bg-no-repeat bg-cover border border-grayBtn rounded flex justify-center items-center h-36 hover:opacity-70',
      mini: 'bg-white border border-grayBtn rounded h-[52px] flex items-center justify-center hover:opacity-70',
      over: 'w-fit',
    },
  },
})

const contentVariants = cva(null, {
  variants: {
    variant: {
      default:
        'text-lg leading-[140%] font-bold text-brand bg-white rounded-[81px] py-3 px-[22px] w-fit',
      mini: 'text-body-bold text-brand',
      over: 'text-body-bold text-white bg-[rgba(0,0,0,0.7)] rounded-[55px] h-16 w-[200px]',
    },
  },
})

type Props = {
  variant?: LinkToMapVariant
  iconIdSuffix?: string
  href?: string
  textOverride?: string
} & AnchorHTMLAttributes<HTMLAnchorElement>

export const LinkToMap: FC<Props> = ({
  variant = 'default',
  iconIdSuffix = 'default',
  className,
  href = '/map',
  textOverride,
  ...props
}) => {
  const { text, iconProps } = variantSetting[variant]

  return (
    <a
      className={twMerge('block', boxVariants({ variant }), className)}
      href={href}
      {...props}
    >
      <div
        className={twJoin(
          'flex items-center justify-center gap-x-2',
          contentVariants({ variant }),
        )}
      >
        <IconSmallLogo {...iconProps} idSuffix={iconIdSuffix} />
        <span>{textOverride ?? text}</span>
      </div>
    </a>
  )
}
